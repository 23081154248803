// Styling for the newsletter section
section#newsletter {
  background-color: $gray-900;
  background-image: url(../_images/map-image.png);
  background-repeat: no-repeat;
  background-position: center;
  .section-heading {
    color: $white;
  }
  .section-subheading {
    margin-bottom: 25px;
  }
  .form-group {
    margin-bottom: 25px;
    input,
    textarea {
      padding: 20px;
    }
    input.form-control {
      height: auto;
      width: 100%;
    }
    textarea.form-control {
      height: 248px;
    }
  }
  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
  ::-webkit-input-placeholder {
    font-weight: 700;
    color: $gray-400;
  }
  :-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    /* Firefox 18- */
  }
  ::-moz-placeholder {
    font-weight: 700;
    color: $gray-400;
    /* Firefox 19+ */
  }
  :-ms-input-placeholder {
    font-weight: 700;
    color: $gray-400;
  }
}
div#mc_embed_signup {
  background:#fff;
  clear:left;
  font:$size-7 Helvetica,Arial,sans-serif;
}
form#mc-embedded-subscribe-form {
  display: inline;
  width: auto;
  margin-right: 10px;
  .mc_fieldset {
    border:none;
    min-height: 0px;
    padding-bottom:0px;
  }
}
.heading-modal {
  font-weight: 700!important;
  font-size: $size-6!important;;
  color: #595959
}
.subtitle-modal{
  font-weight: 600!important;;
  font-size: $size-6!important;;
  color: black;
  padding-bottom: 10px;
}
.img-modal{
  height: 15%;
  width: 15%;
  float: left;
  padding-right: 20px;
}
.text-mclegal{
  font-size: 0.6rem!important;;
  color: $text-color-3;
  display: inline;
}
.button-subscribe{
  background-color: $primary!important;
}
